import React, { useMemo } from 'react';

import CustomLink from 'elements/link';
import { get } from 'lodash';

export const useReportTable = (data) => {
  const { columns, memoizedData } = useMemo(() => ({
    memoizedData: data,
    columns: [
      {
        Header: 'Resource',
        accessor: 'resFullname',
        Cell: (cellInfo) => {
          const id = get(cellInfo, 'row.original.resId', null);
          const name = get(cellInfo, 'row.original.resFullname', '-');
          if (!id) {
            return name;
          }
          return (
            <CustomLink
              pathname={`/delivery/employee/${id}/work-book`}
              id={id}
              data={name}
            />
          );
        },
      },
      {
        Header: 'Role',
        accessor: 'role',
        isSort: false,
      },
      {
        Header: 'Category',
        accessor: 'internalCategoryName',
      },
      {
        Header: 'Missing Review Period',
        accessor: 'missingReviewPeriod',
      },
      {
        Header: 'Last Review Week',
        accessor: 'lastReviewWeek',
      },
      {
        Header: 'Previous Review Week',
        accessor: 'previousReviewWeek',
      },
    ],
  }), [data]);

  return { columns, data: memoizedData };
};
